import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ComponentComponent } from './component/component.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { LoginBaseComponent } from './login/login-base/login-base.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { ShortformComponent } from './component/shortform/shortform.component';
import { ProfileComponent } from './login/profile/profile.component';
import { AuthGuard } from './service/auth.guard';
import { MenuGuard } from './service/menu.guard';
import { AccessGuard } from './service/access.guard';
import { SubmenuGuard } from './service/submenu.guard';
import { ContactComponent } from './login/contact/contact.component';
import { EmailTemplateComponent } from './component/enit/EnitCompliance/email-template/email-template.component';
// import { PreviousQuarterMlaiListComponent } from './component/enit/EnitCompliance/previous-quarter-mlai-list/previous-quarter-mlai-list.component';
import { UsercontrolComponent } from './component/usercontrol/usercontrol.component';
import { CelUserManagementComponent } from './component/cel/cel-user-management/cel-user-management.component';
import { AddCelUserComponent } from './component/cel/add-cel-user/add-cel-user.component';
import { CelAuditComponent } from './component/cel/cel-audit/cel-audit.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { RemoteBrokerDealerComponent } from './remote-broker-dealer/remote-broker-dealer.component';


const routes: Routes = [
  {
    path: 'login', component: LoginBaseComponent,
    loadChildren: () => import('./login/login.module')
      .then(m => m.LoginModule)
  }, {
    path: 'verifyEmail', component: VerifyEmailComponent
  },
  {
    path: 'rtpRegistration', component: RemoteBrokerDealerComponent,
  },
  {
    path: '', component: ComponentComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: DashboardComponent },
      {
        path: 'form', component: ShortformComponent,
        children: [{ path: 'changepassword', component: ChangePasswordComponent },
        { path: 'profile', component: ProfileComponent },
        { path: 'contact', component: ContactComponent }
        ]
      },
      {
        path: 'admin', canActivate: [MenuGuard, AccessGuard],
        children: [{ path: 'userlist', canDeactivate: [SubmenuGuard], loadChildren: () => import('./component/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },
        { path: 'useraccess', canDeactivate: [SubmenuGuard], loadChildren: () => import('./component/accessmanagement/accessmanagement.module').then(m => m.AccessmanagementModule) },
        { path: 'celUserMgmt', canDeactivate: [SubmenuGuard], component: CelUserManagementComponent },
        { path: 'addCelUser', canDeactivate: [SubmenuGuard], component: AddCelUserComponent },
        { path: 'celAudit', canDeactivate: [SubmenuGuard], component: CelAuditComponent }]
      },
      { path: 'menu', loadChildren: () => import('./component/menumanagement/menumanagement.module').then(m => m.MenumanagementModule) },
      { path: 'role', loadChildren: () => import('./component/rolemanagement/rolemanagement.module').then(m => m.RolemanagementModule) },
      { path: 'eTrade', canActivate: [MenuGuard, AccessGuard], loadChildren: () => import('./component/enit/eTrade-routing.module').then(m=>m.EnitRoutingModule)},
      { path: 'tradeAdmin', canActivate: [MenuGuard, AccessGuard], loadChildren: () => import('./component/enit/tradeAdmin-routing.module').then(m=>m.TradeAdminRoutingModule)},
      { path: 'eCompliance',canActivate: [MenuGuard, AccessGuard], loadChildren: () => import('./component/enit/eComplaince-routing.module').then(m=>m.EnitComplainceRoutingModule)},
      { path: 'complianceAdmin',canActivate: [MenuGuard, AccessGuard], loadChildren: () => import('./component/enit/complainceAdmin-routing.module').then(m=>m.ComplainceAdminRoutingModule)},
      { path: 'extranet', canActivate: [MenuGuard, AccessGuard], loadChildren: () => import('./component/extranet/extranet.module').then(m => m.ExtranetModule) },
      { path: 'cel', canActivate: [MenuGuard, AccessGuard], loadChildren: () => import('./component/cel/cel.module').then(m => m.CelModule) },
      {
        path: 'usercontrol', canActivate: [AccessGuard], component: UsercontrolComponent,
        children: [{
          path: 'admin', canActivate: [MenuGuard],
          children: [{ path: 'userlist', canDeactivate: [SubmenuGuard], loadChildren: () => import('./component/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },
          { path: 'useraccess', canDeactivate: [SubmenuGuard], loadChildren: () => import('./component/accessmanagement/accessmanagement.module').then(m => m.AccessmanagementModule) },
          { path: 'celUserMgmt', canDeactivate: [SubmenuGuard], component: CelUserManagementComponent },
          { path: 'addCelUser', canDeactivate: [SubmenuGuard], component: AddCelUserComponent }]
        },
        { path: 'extranet', canActivate: [MenuGuard], loadChildren: () => import('./component/extranet/extranet.module').then(m => m.ExtranetModule) },
        { path: 'cel', canActivate: [MenuGuard], loadChildren: () => import('./component/cel/cel.module').then(m => m.CelModule) }
        ]
      }
      
 
    ]
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [AuthGuard, MenuGuard, SubmenuGuard, AccessGuard]
})
export class AppRoutingModule {

}
