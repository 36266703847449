// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  URL: window.location.protocol + '//' + window.location.hostname + (window.location.hostname.includes('nseifsc') ? '' : (':' + window.location.port)) + window.location.pathname + 'app',
  SSEURL: window.location.protocol + '//' + window.location.hostname + (window.location.hostname.includes('nseifsc') ? '' : (':' + window.location.port)) + window.location.pathname ,
//  URL:'http://localhost:9030',
//  SSEURL:'http://localhost:9030/security'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
